import {Box, Divider, IconButton, Typography} from "@mui/material";
import {InstagramLogo, Mailbox} from "@phosphor-icons/react";
import {globalPadding} from "../constants";
import {backgroundColorTransition} from "../colorUtils";
import {useTheme} from "@mui/material/styles";

export default function Footer() {
    const theme = useTheme();

    return (
        <Box component={"footer"} sx={{p: globalPadding, backgroundColor: theme.palette.background.default, ...backgroundColorTransition}}>
            {/* thickness and rounded ends */}
            <Divider/>
            <Typography component={"a"} href={"/privacy.html"} display={"block"} color={"inherit"} sx={{pt: 3}}>
                Privacy
            </Typography>
            <Typography component={"a"} href={"/terms.html"} display={"block"} color={"inherit"}>
                Terms
            </Typography>
            <Box sx={{
                pt: 3,
                display: "flex",
                alignItems: "center",
            }}>
                <Typography variant="body1" fontWeight={"bold"} sx={{flexGrow: 1}}>
                    Copyright © {new Date().getFullYear()} Tacit Dating.
                </Typography>
                <IconButton color={"inherit"} href={"https://www.instagram.com/tacitdating/"}>
                    <InstagramLogo/>
                </IconButton>
                <IconButton color={"inherit"} href={"mailto:hi@tacitness.com"}>
                    <Mailbox/>
                </IconButton>
            </Box>
        </Box>
    );
}