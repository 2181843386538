import WalkingTogether from "./images/WalkingTogether";
import {Box} from "@mui/material";
import {backgroundColorTransition} from "../colorUtils";
import {useTheme} from "@mui/material/styles";
import Cooking from "./images/Cooking";
import {createElement, CSSProperties, useEffect, useState} from "react";
import PersonalTrainer from "./images/PersonalTrainer";
import Travelling from "./images/Travelling";
import FunMoments from "./images/FunMoments";
import WinterSkating from "./images/WinterSkating";
import Relaxation from "./images/Relaxation";

const images = [
    WalkingTogether,
    Cooking,
    // InLove,
    Travelling,
    FunMoments,
    // HappyNewYear,
    PersonalTrainer,
    Relaxation,
    WinterSkating,
];

export default function BackgroundImage() {
    const [index, setIndex] = useState(0);
    const theme = useTheme();

    useEffect(() => {
        setIndex((index) => (index + 1) % images.length);
    }, [theme]);

    return (
        <Box sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            zIndex: -10,
            backgroundColor: theme.palette.background.default,
            ...backgroundColorTransition,
        }}>
            <Box width={512} height={512} maxWidth={"100%"} maxHeight={"100%"} position={"relative"}
                 sx={{opacity: "33%"}}>
                {images.map((image, i) => <Box
                    key={i}
                    sx={{
                        opacity: index == i ? 1 : 0,
                        transition: "all 2000ms"
                    }}>
                    {createElement(image, {
                        color: theme.palette.primary.main,
                    })}
                </Box>)}
            </Box>
        </Box>
    )
}

// https://stackoverflow.com/questions/9566792/scale-svg-to-container-without-mask-crop
export const svgStyle: CSSProperties = {position: "absolute", left: 0, top: 0, width: "100%", height: "100%",}