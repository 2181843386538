import {globalPadding} from "../constants";
import {Box, Button, Container, Grid, TextField, Typography} from "@mui/material";
import {backgroundColorTransition} from "../colorUtils";
import {useTheme} from "@mui/material/styles";
import {ShootingStar} from "@phosphor-icons/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useContext, useState} from "react";
import {FunctionsError, getFunctions, httpsCallable} from "firebase/functions";
import {useSnackbar} from 'notistack';
import {AppContext} from "../index";

export default function Newsletter() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const app = useContext(AppContext);

    const functions = getFunctions(app);
    const signUp = httpsCallable(functions, 'signUpToNewsletter');

    return (
        <Box id={"newsletter"} sx={{
            backgroundColor: theme.palette.background.default + "88",
            backdropFilter: "blur(10px)",
            ...backgroundColorTransition,
        }}
             textAlign={matches ? "center" : "start"}
        >
            <Container maxWidth={"xs"} sx={{p: globalPadding, pt: 16, pb: 16}}>
                <Typography fontWeight={"bold"} variant={"h4"} paragraph>
                    Sign up for updates
                </Typography>
                <Typography variant={"h6"} paragraph>
                    Get notified about exciting new releases.
                </Typography>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12} sm={8}>
                        <TextField variant={"filled"} label={"Email address"} fullWidth
                                   onChange={(e) => setEmail(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button variant={"contained"} startIcon={<ShootingStar weight={"fill"}/>} sx={{height: "100%"}}
                                fullWidth disabled={isLoading} onClick={async () => {
                            if (email.length === 0) {
                                return
                            }
                            try {
                                setIsLoading(true);
                                await signUp({email: email})
                                enqueueSnackbar("Success!")
                            } catch (e) {
                                if (e instanceof FunctionsError) {
                                    enqueueSnackbar(e.message);
                                }
                            } finally {
                                setIsLoading(false);
                            }
                        }}>
                            Sign up
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}