import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import {initializeApp} from 'firebase/app';
import {connectFunctionsEmulator, getFunctions} from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA1mmBQtqCnTK6PD3O0pox1jkKhWJssQgs",
    authDomain: "tacitness.firebaseapp.com",
    databaseURL: "https://tacitness-default-rtdb.firebaseio.com",
    projectId: "tacitness",
    storageBucket: "tacitness.firebasestorage.app",
    messagingSenderId: "754562000855",
    appId: "1:754562000855:web:063396a63abe7abf718d92"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// initialise emulators
if (window.location.hostname === "localhost") {
    const functions = getFunctions(app);
    connectFunctionsEmulator(functions, "localhost", 5001);
}

export const AppContext = createContext(app);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AppContext.Provider value={app}>
            <App/>
        </AppContext.Provider>
    </React.StrictMode>
);