import React, {useEffect, useState} from 'react';
import Masthead from "./Masthead";
import Statement2 from "./Statement2";
import Footer from "./Footer";
import Hero2 from "./Hero2";
import BackgroundImage from "./BackgroundImage";
import Cookies from "./Cookies";
import {themeFromColors} from "../theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {primaries, shadeColor} from "../colorUtils";
import Newsletter from "./Newsletter";
import {SnackbarProvider} from "notistack";

function App() {
    const [index, setIndex] = useState(5);

    useEffect(() => {
        setTimeout(
            () => {
                setIndex((index) => (index + 1) % primaries.length);
            }, 4000,
        );
    }, [index]);

    return (
        <ThemeProvider
            theme={themeFromColors({main: primaries[index], background: shadeColor(primaries[index], -180)})}>
            <SnackbarProvider>
                <CssBaseline/>
                <BackgroundImage/>
                <Masthead/>
                <Hero2/>
                <Statement2/>
                <Newsletter/>
                <Footer/>
                <Cookies/>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;