import {Box, Button, Grid, Typography} from "@mui/material";
import {AppleLogo, GooglePlayLogo} from "@phosphor-icons/react";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Hero2() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box
            maxWidth={"100%"}
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={matches ? "center" : "start"}
            px={4}
            textAlign={matches ? "center" : "start"}
        >
            <Typography variant={matches ? "h2" : "h3"} fontWeight={"bold"} component={"h1"}>
                Make emotional connections
            </Typography>
            <Box height={20}/>
            <Typography variant={matches ? "h5" : "h6"} component={"p"}>
                {/*This is fine. But at a deeper level, we aren't just looking for personality, but a connection*/}
                {/*Text-based dating app putting personality before looks*/}
                {/*Text-based dating app for making emotional connections*/}
                Text-based dating app for meaningful conversations {matches && <br/>} and genuine relationships
            </Typography>
            <Box height={50}/>
            <ActionButtons/>
        </Box>
    );
}

function ActionButtons() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const buttonStyle = {
        px: 6,
        fontWeight: "bold",
        transition: "all 500ms",
    };

    return (
        <Grid container spacing={2} direction="row" sx={{mt: 2}} justifyContent={matches ? "center" : "start"}>
            <Grid item>
                <Button variant="contained" size={matches ? "large" : "medium"} startIcon={<GooglePlayLogo weight={"fill"}/>} sx={buttonStyle}>
                    Google Play
                </Button>
            </Grid>
            <Grid item>
                <Button variant="outlined" size={matches ? "large" : "medium"} startIcon={<AppleLogo weight={"fill"}/>} sx={buttonStyle} onClick={() => document.querySelector("#newsletter")?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                })}>
                    coming soon
                </Button>
            </Grid>
        </Grid>
    )
}